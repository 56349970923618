<script>
    export let subline
</script>

<p class:subline>
    <slot></slot>
</p>

<style>
    p {
        font-size: 16pt;
        margin: 0;
        font-weight: 500;
        color: #F0F0F4;
    }

    .subline {
        opacity: 0.5;
    }
</style>