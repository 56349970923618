<div class="row">
    <div class="header-text six columns">
        <h1>Gain superpowers.<br>For your favorite tools.</h1>
        <p>A curated library of productivity hacks. Uncover the hidden 1% in every tool you use. This will compound and be your new superpowers.</p>

        <Email />
    </div>

    <div class="six columns">
        <div class="logo-canvas">
            {#each products as product, i}
                <ShortcutCard name={product} isAbsolute={true} index={i}/>
            {/each}
        </div>
    </div>
</div>

<script>
    import ShortcutCard from './ShortcutCard.svelte'
    import Email from './Email.svelte'

    let products = ["chrome", "snapchat", "amie", "apple", "spotify", "siri", "alfred", "whatsapp", "docs", "slides", "sheets", "twitter", "shazam", "safari"]
</script>

<style>

    h1 {
        margin-bottom: 8px;
    }

    .row {
        margin-top: 32vh;
        margin-bottom: 32vh;
    }

    .logo-canvas {
        position: relative;
    }

    @media (max-width: 1000px) {
        .logo-canvas {
            display: none;
        }
    }
</style>