<div class="container">
    {#if valueCopy != null}
        <textarea bind:this={areaDom}>{ valueCopy }</textarea>
    {/if}
    <div class="row">
        <div class="six columns">
            <h1>👋 About us</h1>
            <p>Hey, we are <a class="dark" href="https://twitter.com/lukasklinser">Luke</a> and <a class="dark" href="https://twitter.com/dennismuellr">Denninho</a>. We created productivity.so because somehow we always ended up telling our friends about little UX hacks or the coolest shortcuts.</p>

            <p>Productivity is our joint side project. This was motivated by <a class="dark" href="https://amie">amie</a>. Dennis spends his time working on it. It is the joyful productivity app. And integrates to-dos into your calendar like never before! <span class="dark">🤞</span></p>

            <p>In case of question, DM us on Twitter or feel free to mail <span class="dark fakeLink" on:click={copy}>dennis@amie</span>
            <span transition:fade>{#if valueCopy != null}
                ({ message })
            {:else}
                (tap to copy)
            {/if}
            
            </span>
        </div>

        <div class="six columns">
            <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Starting a side project very closely related to <a href="https://t.co/7YwgAYTMzj">https://t.co/7YwgAYTMzj</a> with <a href="https://twitter.com/lukasklinser?ref_src=twsrc%5Etfw">@lukasklinser</a> right now. 🤩<br><br>This will become a mega thread of every step from inception to <a href="https://twitter.com/ProductHunt?ref_src=twsrc%5Etfw">@ProductHunt</a> launch soon™.<br><br>Step 1: Registered a sick domain :)</p>&mdash; Denninho (@dennismuellr) <a href="https://twitter.com/dennismuellr/status/1228074181620879360?ref_src=twsrc%5Etfw">February 13, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        </div>
    </div>
</div>

<script>
    import { fade } from 'svelte/transition';
    import { tick } from 'svelte';

    let valueCopy = null;
    let areaDom;
    let value = null 
    let message = null

    async function copy() {
        valueCopy = "dennis@amie.so";
        await tick();
        areaDom.focus();
        areaDom.select();
        message = 'copied!';
        try {
        const successful = document.execCommand('copy');
        if (!successful) {
            message = 'Copying text was unsuccessful';
        }
        } catch (err) {
            message = 'Oops, unable to copy';
        }

        setTimeout(function(){ valueCopy = null }, 2000);
    }
</script>

<style>
    .container {
        margin-top: 24vh;
    }

    h1 {
        margin-bottom: 16px;   
    }

    .fakeLink {
        cursor: pointer;
    }

    p {
        color: rgb(56,59,64, 0.5);
    }

    a.dark, .dark {
        color: rgb(56,59,64, 1);
        font-weight: 600;
    }

    textarea {
        position: fixed;
        top: 0;
        left: 0;
        width: 0em;
        height: 0em;
        padding: 0;
        border: none;
        outline: none;
        box-shadow: none;
        background: transparent;
    }

</style>