<div class="container">
    <div class="{neumorphismValue ? 'neu' : 'no-neu'}">
        <h1 class="center">😞 Sad to see you leave.</h1>

        <p class="center">You have been removed from our email list. It was lovely to have you part while it lasted! :) </p>  
    </div>
</div>

<script>
    import { neumorphism } from '../components/filter.js'

     var neumorphismValue

    const unsubscribe = neumorphism.subscribe(value => {
		neumorphismValue = value;
	});
</script>

<style>

    p.coco {
        font-size: 2rem;
        font-weight: 400;
        margin-top: 8vh;
    }

    .neu {
        display: flex;
        flex-direction: column;
        height: auto;
        width: auto;
        padding: 32px 32px 24px 32px;
        box-sizing: border-box;
        background: linear-gradient(142.01deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), #EBECF0;
        background-blend-mode: soft-light, normal;
        background-repeat: no-repeat;
        border: 4px solid rgba(255, 255, 255, 0.16);
        border-radius: 40px;
        box-shadow: 20px 20px 40px rgba(166, 171, 189, 0.64), -20px -20px 40px #FAFBFF;
        transition: 0.33s transform ease-in-out;
   }

    .neu {
        display: flex;
        flex-direction: column;
        height: auto;
        width: auto;
        padding: 32px 32px 24px 32px;
        box-sizing: border-box;
        background-color: #F0F0F0;
        background-repeat: no-repeat;
        border-radius: 40px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        transition: 0.33s transform ease-in-out;
   }

    .container {
        margin-top: 24vh;
    }

    h1 {
        margin-bottom: 8px;
    }

    .spaced {
        margin-left: 8px;
    }

    a {
        font-weight: 600;
    }

    p {
        color: rgb(56,59,64, 0.5);
    }
    
    .flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>