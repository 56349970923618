<svelte:head>
	<title>{post.description}</title>

    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content="@lukasklinser" />
    <meta name="twitter:creator" content="@dennismuellr" />
    <meta property="og:url" content="https://productivity.so/all/{post.id}" />
    <meta property="og:title" content="Curated library for productivity hacks" />
    <meta property="og:description" content="{post.description} {post.shortcut}" />
    <meta property="og:image" content="http://graphics8.nytimes.com/images/2011/12/08/technology/bits-newtwitter/bits-newtwitter-tmagArticle.jpg" />
</svelte:head>

<div class="container">
    <p class="go-back"><a class="grey" href="/all">← Back to full library</a></p>

	<div class="row">
        <div class="eight columns">
            <h1>{post.description} <span class="dark">{post.shortcut}</span></h1>
            {#if post.sharedBy != null}
                <p><span class="dark">💕</span> Credits to
                    {#if post.isTwitter}
                        <span class="dark">@<a href="https://twitter.com/{post.sharedBy}">{post.sharedBy} -></a></span>
                    {:else}
                        <span class="dark">{post.sharedBy}</span>
                    {/if}
                </p>
            {/if}
        </div>

        <div class="three columns offset-by-one.column">
            <p><span class="dark">Products: </span>
                {#each post.products as product, i}
                    {capitalize(product)}{i < post.products.length-1 ? ", " : ""}
                {/each}
            </p>
            <p><span class="dark">Platform: </span>
                {#each post.platform as p, i}
                    {capitalize(p)}{i < post.platform.length-1 ? ", " : ""}
                {/each}
            </p>
            <p><span class="dark">How it works: </span>
                {post.howItWorks}
            </p>
        </div>
    </div>

    <div class="row center spaced">
        {#if post.youtubeId != ""}
            <div class="video">
                <iframe title="screencast of feature" width="{post.isHorizontal ? 560 : 315}" height="{post.isHorizontal ? 315 : 560}" style="border-radius: 28px;" src="https://www.youtube.com/embed/{post.youtubeId}?autoplay=1&rel=0&showinfo=0&modestbranding=1&mute=1&loop=1&playlist={post.youtubeId}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        {/if}
    </div>
</div>

<script context="module">
    import items from '../components/data.js';
</script>

<script>
	// Comes from App (router)
	export let params = {};
	// Initial value (preload)
    let post = items[params.id];
    
    function capitalize(string) {
        const capitalized = string.replace(/^\w/, c => c.toUpperCase());

        return capitalized
    }
</script>

<style>
    .video {
        display: inline-block;
        padding: 6px 10px 0 10px;
        box-sizing: border-box;
        background: linear-gradient(142.01deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), #EBECF0;
        background-blend-mode: soft-light, normal;
        background-repeat: no-repeat;
        border: 4px solid rgba(255, 255, 255, 0.16);
        border-radius: 40px;
        box-shadow: 20px 20px 40px rgba(166, 171, 189, 0.64), -20px -20px 40px #FAFBFF;
        transition: 0.33s transform ease-in-out;
    }

    .horizontal-mirrored {
       -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
    }

    .spaced {
        margin: 10vh 0;
    }

    .container {
        margin-top: 16vh;
    }

    h1 {
        font-size: 3rem;
        color: rgb(56,59,64, 0.5);
        font-weight: 600;
        margin-bottom: 8px;
   }

   .dark {
       color: rgb(56,59,64, 1)
   }

   .grey {
       color: rgb(56,59,64, 0.5);
   }

   .grey:hover {
       border: none;
   }

   p {
       font-weight: 600;
       font-size: 1.5rem;
       color: rgb(56,59,64, 0.5);
       margin-bottom: 8px;
   }

   .go-back {
       margin-bottom: 8vh;
   }
</style>