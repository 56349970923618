<nav class="">
    <div class="container">
        <div class="four columns">
            <a class="nostyle" href="/"><span class="cmd">⌘</span> <span class="productivityso">productivity.so</span></a>
        </div>

        <div class="ph-button four columns offset-by-four columns right hide-phone">
            <a class="nostyle ph" href="https://www.producthunt.com/posts/productivity-so?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-productivity-so" target="_blank"><img class="ph" src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=184807&theme=light" alt="Productivity.so - Shortcuts and hacks for your favorite tools | Product Hunt Embed" style="width: 250px; height: 54px;" width="250px" height="54px" /></a>
        </div>
    </div>

    <!-- <div class="right four columns">
        <div class="nav-item"><a href="https://coco-goals.typeform.com/to/Ljpim2" target="_blank">Submit productivity hack -></a></div>
    </div> -->
</nav>


<style>
a {
	font-weight: 600;
	color: #27292D;
	text-decoration: none;
}

a:hover {
    border-bottom: unset;
}

.productivityso:hover {
    border-bottom: 2px solid #27292D;
}

.cmd {
    border: 2px solid #27292D;
    border-radius: 4px;
    padding: 2px 4px;
    margin-right: 4px;
}

.cmd, .productivityso {
    font-size: 2rem;
}

a:visited {
	color: #27292D;
	opacity: 0.5;
}

a:not(.nostyle):hover {
    border-bottom: 2px solid #27292D;
}

.ph:hover {
    transform: scale(1.05);
    transition: 0.33s transform;
}

.ph:hover {
    transform: scale(1);
    transition: 0.33s transform;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 8px 0;
    background-color: rgba(237,238,243, 0.7);
    z-index: 2;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
}

.nav-item.is-active {
    border-bottom: 4px solid #27292D;
    font-weight: 500;
}

.nav-item:not(.is-active):not(.is-button):hover {
    opacity: 1;
    transition: opacity 0.5s;
}
</style>

<script>
    export let active;
    export let cmdk;
	$: isActive = str => active === str ? 'is-active' : '';
</script>