<div class="container">
	<div class="footer">
		<p class="row madein">
			productivity.so was built with a lot of love, burgers and Club Mate in Kreuzberg. <span class="dark"><a href="https://twitter.com/dennismuellr/status/1228074181620879360?s=20" target="_blank">Read how -></a></span>
		</p>
		
		<hr> 
		
		<div class="row">
			<div class="four columns">
				<Email />
			</div>

			<div class="four columns">
				<p class="center names">by 
					<a class="dark" href="https://twitter.com/lukasklinser" target="_blank">Luke</a>  <span class="dark">🤝</span>  
					<a class="dark" href="https://twitter.com/dennismuellr" target="_blank">Denninho</a>
					(<a class="light" href="/about">about us</a>)
				</p>
			</div>

			<div class="four columns">
				<p class="right names">an <a class="dark" href="https://amie.so">Amie</a> product</p>
			</div>
		</div>
	</div>
</div>

<script>
	import Email from './Email.svelte';
</script>

<style>
p {
	font-size: 2rem;
	font-weight: 600;
	word-wrap: break-word;
	margin-bottom: 0;
	color: rgb(56,59,64, 0.5)
}

.light {
	color: rgb(56,59,64, 0.5)
}

.madein {
	margin-bottom: 4vh;
}

.names {
	font-size: 1.6rem;
}

.dark {
	color: rgb(56,59,64, 1)
}

@media (max-width: 640px) {
	.footer {
		margin-bottom: 96px;
	}

	.right {
		text-align: center;
	}
}
</style>
