<script>
    export var name
    export var isAbsolute = false
    export var index = 0

    import { scale } from 'svelte/transition';
    import { quintOut } from 'svelte/easing';
    import { elasticInOut } from 'svelte/easing';
    import { neumorphism } from '../components/filter.js'

    function getClassName(index) {
        return 'position-'+index
    }

    var neumorphismValue

    const unsubscribe = neumorphism.subscribe(value => {
		neumorphismValue = value;
	});
    
</script>

<div class="item {neumorphismValue ? 'neu' : 'no-neu'} { isAbsolute ? 'absolute' : '' } { isAbsolute ? getClassName(index)  : '' }"in:scale="{{duration: 500, delay: 500, opacity: 0.5, start: 0.5, easing: quintOut}}" out:scale="{{duration: 1250, delay: 200, opacity: 0.5, start: 0.5, easing: elasticInOut}}">
    <img src='assets/{name}-logo.png' alt="logo {name}">
</div>

<style>
   div.neu {
        width: 48px;
        height: 48px;
        background: linear-gradient(135deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), #EBECF0;
        background-blend-mode: soft-light, normal;
        border: 1px solid rgba(255, 255, 255, 0.16);
        box-sizing: border-box;
        box-shadow: 10px 10px 20px rgba(166, 171, 189, 0.32), -10px -10px 40px rgba(250, 251, 255, 0.6);
        border-radius: 15.36px;
        display: flex;
        align-items: center;
        justify-content: center;
   }

    div.no-neu {
        width: 48px;
        height: 48px;
        background-color: #F0F0F0;
        box-sizing: border-box;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 15.36px;
        display: flex;
        align-items: center;
        justify-content: center;
   }

   img {
       width: auto;
       height: 28px;
   }

   .item {
       margin-right: 8px;
       transition: 0.33s transform ease-in-out;
   }

   .item:hover {
       transform: scale(1.1);
       transition: 0.33s transform ease-in-out;
   }

   .absolute {
       position: absolute;
   }

   .position-0 {
       left: 450px;
       top: 90px;
   }

   .position-1 {
       left: 150px;
       top: 30px;
   }

   .position-2 {
       left: 120px;
       top: 120px;
   }

    .position-3 {
        left: 310px;
        top: -120px;
    }

    .position-4 {
        left: 240px;
        top: -30px;
    }

    .position-5 {
        left: 420px;
        top: 200px;
    }

    .position-6 {
        left: 265px;
        top: 210px;
    }

    .position-7 {
        left: 495px;
        top: -60px;
    }

    .position-8 {
        left: 150px;
        top: -60px;
    }

    .position-9 {
        left: 530px;
        top: 90px;
    }

    .position-10 {
        left: 40px;
        top: 180px;
    }

    .position-10 {
        left: 222px;
        top: 100px;
    }

    .position-11 {
        left: 410px;
        top: -30px;
    }

    .position-12 {
        left: 340px;
        top: 60px;
    }

    .position-13 {
        left: 350px;
        top: 150px;
    }
</style>