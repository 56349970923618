<script>
    import ShortcutCard from '../components/ShortcutCard.svelte';
    import Card from '../components/Card.svelte';
    import items from '../components/data.js';
    import { filter } from '../components/filter.js';

    export var filterValue
    $: filteredList = items.filter(item => JSON.stringify(item.products).indexOf(filterValue) !== -1);

    const unsubscribe = filter.subscribe(value => {
        filterValue = value;
    });

    function resetFilter() {
        filter.set("")
    }
</script>

<div class="container">
    <div class="row">
		<h1 class="twelve columns">🗃 Full library</h1> 
        <p class="subline">Press <span class="dark">⌘+K</span> to filter by product 🔍</p>
        <hr>
	</div>

    

    <div class="shortcut-grid center">
    {#each filteredList as item}
        <Card {...item}/>	
    {/each}
    {#if filteredList.length == 0}

        <div class="empty center">
            <h1 class="huge-emoji">😥</h1>

            <h1>Oh, no results for <b>{filterValue}</b></h1>
            <p>Try <b>filter chrome</b> or <b>filter gmail</b> instead or <br><span class="fakeLink" on:click={resetFilter}>⌫ Reset filter</span></p>
        </div>
            
        <!-- <p>Ohh, dang! We have nothing show for <b>{filterValue}</b>. Yet! Send @<a href="https://twitter.com/dennismuellr" target="_blank"><b>dennismuellr</b></a> a DM and we'll see what we can do</p> -->
    {/if} 
    </div>
    <hr>
</div>

<style> 
    .container {
        margin-top: 16vh;
    }

    .shortcut-grid.center {
        display: flex;
        justify-content: center;
    }

    .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .filter {
       display: flex;
       flex-direction: row;
   }

   h1 {
       margin-bottom: 8px;
       display: block;
   }

   h1.huge-emoji {
       font-size: 10rem !important;
   }

   p.subline {
       font-weight: 600;
       color: rgb(56,59,64, 0.5);
   }

   .dark {
      color: rgb(56,59,64, 1); 
   }

   .fakeLink {
       font-weight: 600;
       color: rgb(56,59,64, 0.5);
   }

   .fakeLink:hover {
      color: rgb(56,59,64, 1);  
   }
</style>