<script>
    import ShortcutCard from './ShortcutCard.svelte'
    import { neumorphism } from '../components/filter.js'

    export var id
    export var products
    export var description
    export var shortcut
    export var sharedBy = null
    export var isTwitter = null

    var neumorphismValue

    const unsubscribe = neumorphism.subscribe(value => {
		neumorphismValue = value;
	});
</script>

<div class="{neumorphismValue ? 'neu' : 'no-neu'}">
    <a href="/all/{id}">
        <div class="tools">
            {#each products as product}
                <ShortcutCard name={product} />    
            {/each}
        </div>

        <p>{description} <span class="dark">{shortcut}</span></p>
        {#if sharedBy != null}
        <p class="footnote">Credits to 
            {#if isTwitter}
                <a href="https://twitter.com/{sharedBy}">@{sharedBy} -></a>
            {:else}
                {sharedBy}
            {/if}
        </p>
        {/if}
    </a>
</div>


<style>
    div.neu, div.no-neu {
        display: inline-block;
        flex: 0 0 auto;
    }

   div.neu {
        height: auto;
        padding: 32px 32px 24px 32px;
        box-sizing: border-box;
        background: linear-gradient(142.01deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), #EBECF0;
        background-blend-mode: soft-light, normal;
        background-repeat: no-repeat;
        border: 4px solid rgba(255, 255, 255, 0.16);
        border-radius: 40px;
        box-shadow: 20px 20px 40px rgba(166, 171, 189, 0.64), -20px -20px 40px #FAFBFF;
        transition: 0.33s transform ease-in-out;
        min-height: 240px;
        margin-bottom: 24px;
   }

   div.no-neu {
        height: auto;
        padding: 32px 32px 24px 32px;
        box-sizing: border-box;
        background-color: #F0F0F0;
        background-repeat: no-repeat;
        border-radius: 40px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        transition: 0.33s transform ease-in-out;
        min-height: 240px;
        margin-bottom: 24px;
   }

    div.neu:nth-child(3n+2), div.neu:nth-child(3n+3),
    div.no-neu:nth-child(3n+2), div.no-neu:nth-child(3n+3) {
        margin-left: 2%;
    }


    div.neu, div.no-neu {
        width: 32%;
    }

    @media (max-width: 1000px) {
		div.neu, div.no-neu {
            width: 48%;
        }

        div.neu:nth-child(3n+2), div.neu:nth-child(3n+3),
        div.no-neu:nth-child(3n+2), div.no-neu:nth-child(3n+3) {
            margin-left: 0%;
        }

        div.neu:nth-child(2n+2), div.no-neu:nth-child(2n+2) {
            margin-left: 2%;
        }
    }

    @media (max-width: 480px) {
		div.neu, div.no-neu {
            width: 100%;
            margin-left: 0 !important;
		}
    }
    


    
    div.neu:hover {
       transform: scale(1.01);
       transition: 0.33s transform ease-in-out;
   }

   .tools {
       display: flex;
       flex-direction: row;
       margin-bottom: 16px;
   }

   p {
       font-size: 2rem;
       text-align: left;
       font-weight: 600;
       color: rgb(56,59,64, 0.5)
   }

   .dark {
       color: rgb(56,59,64, 1)
   }

   .tags {
       display: flex;
       justify-content: left;
   }

   .cardfooter {
       display: flex;
       justify-content: space-between;
       align-items: center;
   }

   .footnote {
       font-size: 1rem;
       margin-bottom: 0;
   }

   .footnote a:hover {
       border-bottom: none;
   }
   
</style>