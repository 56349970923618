<script>
	import Footer from '../components/Footer.svelte';
	import Card from '../components/Card.svelte';
	import HeaderArea from '../components/HeaderArea.svelte';
	import items from '../components/data.js';
	import { filter } from '../components/filter.js';

    export var filterValue
	$: filteredList = items.filter(item => JSON.stringify(item.products).indexOf(filterValue) !== -1).slice(0,6);
	$: fakeList = items.slice(0,3);

    const unsubscribe = filter.subscribe(value => {
        filterValue = value;
    });
</script>

<svelte:head>
	<title>productivity.so</title>
</svelte:head>

<div class="container">
	<HeaderArea />

	<div class="row">
		<h1 class="twelve columns">💫 Our favorites</h1> 
	</div>
<!-- 
	<div class="row shortcut-row">
		{#each filteredList as item}
			<Card {...item}/>	
		{/each}
	</div> -->

	<div class="shortcut-grid">
		{#each filteredList as item}
			<Card {...item}/>	
		{/each}
	</div>

	<div class="overlay">
		<p class="text"><a href="/all">Free full library -></a></p>
		<p class="subtext">Or ⌘+K: <i>Go to library</i></p>
		<div class="shortcut-grid blur">
			{#each fakeList as item}
			<Card {...item}/>	
		{/each}
		</div>
	</div>
</div>

<style>
	.container {
		margin-top: 16vh;
	}

	.blur {
		filter: blur(8px);
		opacity: 0.75;
	}

	.overlay {
        position: relative;
        display: inline-block; /* Make the width of box same as image */
	}

	.text, .subtext {
		font-weight: 600;
        position: absolute;
        z-index: 999;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 40%; /* Adjust this value to move the positioned div up and down */
        text-align: center;
        width: 60%; /* Set the width of the positioned div */
	}

	.text {
		font-size: 2rem;
	}

	.subtext {
		top: 50%;
		font-weight: 400;
		color: rgb(56,59,64, 0.5)
	}
</style>


