<div class="cmdk-wrapper" on:keydown={handleKeydown}>
    <div id="cmdk">
      <span class="cmdlogo">⌘</span> <span class="productivityso">productivity.so commands</span>
      <hr>
      <input placeholder="Enter command" bind:value={text} use:init />
      <!-- <FootnoteText subline={true}>Press Enter↵ to execute</FootnoteText> -->

      {#if filterValue != ""}
        <div class="cmdk-tip"><XSquareIcon size="16" /> <span class="cmdk-tip-text">Filter reset (currently filtered by {filterValue})</span></div>
      {:else} 
        <div class="cmdk-tip"><GridIcon size="16" /> <span class="cmdk-tip-text"><i>filter chrome</i> ~ Filter by product</span></div>
      {/if}

      <div class="cmdk-tip"><TwitterIcon size="16" /> <span class="cmdk-tip-text"><i>tweet</i> ~ Tweet about productivity.so</span></div>
      <div class="cmdk-tip"><SendIcon size="16" /> <span class="cmdk-tip-text"><i>submit</i> ~ Submit a hack</span></div>
      <div class="cmdk-tip"><MailIcon size="16" /> <span class="cmdk-tip-text"><i>email name@domain.so</i> ~ Share us via email</span></div>
      <div class="cmdk-tip"><MailIcon size="16" /> <span class="cmdk-tip-text"><i>go to library</i> ~ Navigate to library or home</span></div>
      <div class="cmdk-tip"><FigmaIcon size="16" /> <span class="cmdk-tip-text"><i>figma</i> ~ Check our Figma file</span></div>
      
    </div>
  </div>

<script>
  import FootnoteText from './FootnoteText.svelte'
  import BodyText from './BodyText.svelte'
  import { filter, neumorphism } from './filter.js'
  import { SendIcon, TwitterIcon, FigmaIcon, MailIcon, GridIcon, XSquareIcon } from 'svelte-feather-icons'


  let text = "";
  var filterValue
  var neumorphismValue

  const unsubscribe = filter.subscribe(value => {
		filterValue = value;
  });
  
  const unsubscribe2 = neumorphism.subscribe(value => {
		neumorphismValue = value;
	});

  function init(el) {
      el.focus();
  }

  function handleKeydown(event) {
    if (cmdk) {
      if (event.keyCode === 13) {
        if (text.startsWith("submit")) {
            submitHack()
        }
        if (text.startsWith("twitter") || text.startsWith("tweet")) {
          shareOnTwitter()
        }
        if (text.startsWith("email") || text.startsWith("mail")) {
          shareViaEmail()
        }
        if (text.startsWith("guestlist")) {
          signIntoGuestlist()
        }
        if (text.startsWith("filter")) {
          setFilter()
        }
        if (text.startsWith("go to")) {
          goToPage()
        }
        if (text.startsWith("figma")) {
          openFigmaFile()
        }
        if (text.startsWith("neumorphism") || text.startsWith("neu")) {
          toggleDesignStyle()
        }
      }
    }
  }
  
  function submitHack() {
    window.open('https://coco-goals.typeform.com/to/Ljpim2', '_blank');
  }

  function shareOnTwitter() {
    window.open('https://twitter.com/intent/tweet?text=%40lukasklinser%20and%20%40dennismuellr%20built%20productivity.so%20and%20gave%20me%20superpowers%20%F0%9F%A4%A9%F0%9F%9B%AC', '_blank'); 
  }

  function openFigmaFile() {
    window.open('https://www.figma.com/file/LwrNhyK9jAWu6Yufj8LDXR/Productivity.so?node-id=5%3A0', '_blank'); 
  }

  function shareViaEmail() {
    var email = text.replace("email ", "");
    email = email.replace("Email ", "");
    var link = "mailto:" + email + "?subject=This website is cool af!&body=Check out https://productivity.so. It showcases productivity hacks for my favorite apps. 🤩"
    window.open(link, '_blank'); 
  }

  function setFilter() {
    var filterby = text.replace("filter ", "")
    filterby = filterby.replace("Filter ", "")

    filterby == "reset" ? filterby = "" : filterby
    filter.set(filterby)
  }

  function toggleDesignStyle() {
    var flag = text.replace("neumorphism ", "")
    flag = flag.replace("neumorphism ", "")

    neumorphism.set(false)
  }

  function signIntoGuestlist() {
    var name = text.replace("guestlist ", "")
    name = name.replace("Guestlist ", "")
  }

  function goToPage() {
    var page = text.replace("go to ", "")
    page = page.replace("Go to ", "")

    if (page == "homepage" || page == "home") {
      window.location.replace('https://productivity.so');
    }
    else if (page == "library" || page == "full library") {
      window.location.replace('https://productivity.so/all');
    }
    else if (page == "about") {
      window.location.replace('https://productivity.so/about');
    }
  }
</script>

<style>
.cmdk-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-transition: -webkit-backdrop-filter 500ms linear;
    -webkit-transition: 1s -webkit-backdrop-filter linear;
    -moz-transition: 1s -moz-backdrop-filter linear;
    -moz-transition: 1s backdrop-filter linear;
    -ms-transition: 1s -ms-backdrop-filter linear;
    -o-transition: 1s -o-backdrop-filter linear;
    transition: 1s backdrop-filter linear, 1s -webkit-backdrop-filter linear;
    z-index: 10;
  }

  #cmdk {
    position: fixed;
    width: 60%;
    background-color: #010101;
    opacity: 0.9;
    border-radius: 8px;
    padding: 32px;
  }

  .cmdk-tip-text {
    margin-left: 8px;
  }

  .cmdlogo {
    border: 2px solid #F0F0F4;
    border-radius: 4px;
    padding: 2px 4px;
    margin-right: 4px;
}

.cmdk-tip {
  margin-bottom: 4px;
  color: #F0F0F4;
  opacity: 0.32;
}

.cmdk-tip:last-child {
  margin-bottom: 0;
}

.cmdlogo, .productivityso {
  color: #F0F0F4;
  opacity: 0.32;
}

hr {
  background-color: #F0F0F4;
  opacity: 0.32;
  margin: 16px 0;
}

  #cmdk input,
  #cmdk input:focus {
    font-size: 3rem;
    border: none;
    outline: none;
    background-color: #010101;
	  color: #f0f0f4;
    width: 100%;
    padding-left: 0;
  }
</style>