<svelte:head>
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NBQB2LQ');</script>
<!-- End Google Tag Manager -->
</svelte:head>

<Nav {active} />

<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NBQB2LQ"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->

{#if cmdk}
	<CmdK filter={filter} />
{/if}
<svelte:window on:keydown={handleKeydown} />
<svelte:component this={Route} {params} filter={filter}  />

{#if uri != '/thanks'}
<Share />
{:else}
<div style="height: 25vh"></div>
{/if}
<Footer />

<div class="hide-desktop center ph-desktop">
	<a class="nostyle ph" href="https://www.producthunt.com/posts/productivity-so?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-productivity-so" target="_blank"><img class="ph" src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=184807&theme=light" alt="Productivity.so - Shortcuts and hacks for your favorite tools | Product Hunt Embed" style="width: 250px; height: 54px;" width="250px" height="54px" /></a>
</div>

<script>
	import Navaid from 'navaid';
	import { onDestroy } from 'svelte';
	import Nav from './Nav.svelte';
	import Footer from './Footer.svelte';
	import Share from './Share.svelte';
	import CmdK from '../components/CmdK.svelte';
	import { filter } from '../components/filter.js'

	let Route, params, active;
	let uri = location.pathname;
	$: active = uri.split('/')[1] || 'home';

	function draw(m, obj) {
		params = obj || {};
		if (m.preload) {
			m.preload({ params }).then(() => {
				Route = m.default;
				window.scrollTo(0, 0);
			});
		} else {
			Route = m.default;
			window.scrollTo(0, 0);
		}
	}

	function track(obj) {
		uri = obj.state || obj.uri || location.pathname;
		if (window.ga) ga.send('pageview', { dp:uri });
	}

	function handleClick() {
		cmdk = false
	}

	addEventListener('replacestate', track);
	addEventListener('pushstate', track);
	addEventListener('popstate', track);

	const router = Navaid('/')
		.on('/', () => import('../routes/Home.svelte').then(draw))
		.on('/all', () => import('../routes/AllHacks.svelte').then(draw))
		.on('/all/:id', obj => import('../routes/Article.svelte').then(m => draw(m, obj)))
		.on('/thanks', () => import('../routes/Thanks.svelte').then(draw))
		.on('/goodbye', () => import('../routes/Goodbye.svelte').then(draw))
		.on('/about', () => import('../routes/About.svelte').then(draw))
		.listen();

	onDestroy(router.unlisten);

	let cmdk = false;
	let filterValue

	const unsubscribe = filter.subscribe(value => {
		filterValue = value;
	});

	function handleKeydown(event) {
		if (event.keyCode === 75 && event.metaKey) {
			cmdk = true;
		} 
		else if (event.keyCode === 27) {
			cmdk = false;
		}
		else if (event.keyCode === 13) {
			cmdk = false;
		}
	}
</script>

<style>
	main {
		text-align: center;
		font-family: 'Inter', sans-serif;
		padding: 1em;
		max-width: 100vw;
		min-height: 100vh;
		margin: 0 auto;
		background-color: #EBECF0;
	}

	.header-text {
		text-align: left;
	}

	h1 {
		font-size: 3rem;
		font-weight: 600;
		word-wrap: break-word;
		text-align: left;
		margin-bottom: 0;
	}

	.header-text p {
		font-size: 1.5rem;
		opacity: 0.5;
	}

	.shortcut-row {
  		display: flex;
  		flex-wrap: nowrap;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}

	.ph-desktop {
		position: fixed;
		bottom: 16px;
		left: 16px;
	}
</style>