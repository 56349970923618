<script>
    import { fade } from 'svelte/transition';
    import { tick } from 'svelte';

    let valueCopy = null;
    let areaDom;
    let value = null 
    let message = null

    async function copy() {
        valueCopy = "productivity.so";
        await tick();
        areaDom.focus();
        areaDom.select();
        message = 'Copied productivity.so to clipboard';
        try {
        const successful = document.execCommand('copy');
        if (!successful) {
            message = 'Copying text was unsuccessful';
        }
        } catch (err) {
            message = 'Oops, unable to copy';
        }

        setTimeout(function(){ valueCopy = null }, 2000);
    }
</script>

<div class="container">
    <div class="row">
        {#if valueCopy != null}
            <textarea bind:this={areaDom}>{ valueCopy }</textarea>
        {/if}

        {#if valueCopy != null}
            <p transition:fade>{ message }</p>
        {/if}
        
        <p class="header" on:click={copy}><img alt="share button" src="assets/share.png"> Share superpowers</p>
        <p class="subheader">Be aware with great power comes great responsibility</p>
    </div>
</div>

<style>
    img {
        width: 24px;
        height: auto;
    }

    .container {
        margin-top: 12vh;
        margin-bottom: 12vh;
    }

    p {
        margin-bottom: 0;
        text-align: center;
        font-weight: 600;
    }

    .header {
        font-size: 2.8rem;
        color: rgb(56,59,64, 1)
    }

    .header:hover {
        cursor: pointer;
    }

    .subheader {
        font-size: 1.4rem;
        color: rgb(56,59,64, 0.5)
    }

    textarea {
        position: fixed;
        top: 0;
        left: 0;
        width: 0em;
        height: 0em;
        padding: 0;
        border: none;
        outline: none;
        box-shadow: none;
        background: transparent;
    }
</style>