<!-- Begin Mailchimp Signup Form -->
<div id="mc_embed_signup">
<form action="https://dennismu.us19.list-manage.com/subscribe/post?u=62f26c9ad28c0c469ac47f938&amp;id=21d94c817f" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">

	<input type="email" value="" placeholder="Get new hacks" name="EMAIL" class="required email" id="mce-EMAIL">
    <input type="submit" value="Unlock superpowers" name="subscribe" id="mc-embedded-subscribe" class="button">

	<div id="mce-responses" class="clear">
		<div class="response" id="mce-error-response" style="display:none"></div>
		<div class="response" id="mce-success-response" style="display:none"></div>
	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_62f26c9ad28c0c469ac47f938_21d94c817f" tabindex="-1" value=""></div>
    </div>
</form>
</div>

<script></script>

<style>
    input[type="email"] {
        width: 250px;
        height: 48px;
        background: linear-gradient(352.64deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), #EBECF0;
        background-blend-mode: soft-light, normal;
        border: 0.25px solid rgba(255, 255, 255, 0.502431);
        box-sizing: border-box;
        box-shadow: inset 10px 10px 20px rgba(166, 171, 189, 0.32), inset -10px -10px 20px #FAFBFF;
        border-radius: 15.36px;
        margin-right: 8px;
    }

    input[type="email"]:focus {
       border: 0.25px solid rgba(255, 255, 255, 0.502431);
    }

    .button {
        background-color: #27292D;
        padding: 0 16px;
        height: 48px;
        border-radius: 15.36px;
        color: #F0F0F4;
    }
</style>